import { useState, useEffect, useCallback, MutableRefObject } from "react"
import { IUser } from "./types/admin"
import {
    IPriceList,
    IPriceListVersion
} from "./types/billing"
import {
    IParams,
    IFilter
} from "./types/admin"
import { IFilterField } from "../../mui-base/containers/Page/types"


export function removeUserEmptyFields<T>(data: T): T {
    Object.entries(data as any).map(([key, value]) => {
        const k = key as keyof typeof data
        value = (value == "---") ? null : value

        if ((!value) || (value == 0)) {
            if ((key !== "organization_id") && (key !== "department_id")) {
                (key == "status") ? data[k] = 0 as any : delete data[k]
            } else {
                data[k] = null as any
            }
        }
    })

    return data
}

export function removeEmptyValuesForUser(data: IUser): IUser {
    Object.entries(data).map(([key, value]) => {
        const k = key as keyof IUser
        if ((!value) || (value == 0) || (value == "---")) {
            if (!/status|organization_id|department_id|lang/.test(key)) {
                delete data[k]
            } else {
                if (/organization_id|department_id/.test(key)) {
                    data.department_id = null
                }
                
                if (/organization_id|lang/.test(key)) {
                    data[key as "organization_id" | "lang"] = null
                }
            }
        }
    })

    return data
}

export function removeEmptyFields<T>(data: T): T {
    var newData = {} as any

    Object.entries(data as any).map(([key, value]) => {
        if ((value != 0) && (value != "---")) newData[key] = value
    })

    return newData as T
}

export function removeAnalyseEmptyFields<DataType>(data: DataType): DataType {
    Object.entries(data as any).map(([key, value]) => {
        const k = key as keyof typeof data
        if ((!value) || ((value == 0) && (value !== "0"))) {
            delete data[k]
        }
    })

    return data
}

export function clearNewPriceListItems<T extends IPriceList | IPriceListVersion>(data: T): T {
    data.valid_from_date = data.valid_from_date?.split("T")[0]

    const newItems = data.price_list_items?.map((item) => {
        if (item.id && (item.id >= 1000000000)) delete item.id
        return item
    })
    data.price_list_items = newItems

    return data
}

export function parseFilterValue(value: string, type: string) {
    switch (type) {
        case "number":
            return parseInt(value)
        case "numberForeign":
            return parseInt(value)
        case "boolean":
            return (value === "true")
        case "datetime":
            return `${value.replace("T", " ")}:00`
        default:
            return String(value)
    }
}

export function handleParams(pageName: string, params: IParams, fields: IFilterField[]): IParams {
    var newParams = { ...params }
    const persistFilters = localStorage.getItem("mui_filter")
    if (!persistFilters || !JSON.parse(persistFilters)[pageName]) return params

    const pageFilters = JSON.parse(persistFilters)[pageName]

    if (pageFilters?.queries) pageFilters?.queries.forEach((filter: IFilter) => {
        const currentField = fields.filter((f) => f.name == filter.field)[0]
        if (!currentField) return

        const queryValue = ((currentField.type == "datetime") && (filter.operator == "__date")) ? String(filter.value).split("T")[0] : parseFilterValue(String(filter.value), currentField.type)
        newParams[`${filter.field}${filter.operator}`] = queryValue
    })

    if (pageFilters?.sortField) newParams.ordering = `${(pageFilters?.sortOrientation == "-1") ? "-" : ""}${pageFilters?.sortField}`
    if (pageFilters?.page) newParams.page = pageFilters?.page
    if (pageFilters?.pageSize) newParams.page_size = pageFilters?.pageSize

    return newParams
}
